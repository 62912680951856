import PageHeader from '@/components/PageHeader.vue'
import Adsearch from '@/components/Adsearch'
import tableMixin from '@/minxin/table-minxin'
import root from '@/minxin/root'
// import printJS from 'print-js'
import Print from '@/components/print'
// /引入表格拖拽依赖
// import Sortable from 'sortablejs'
import vuedraggable from 'vuedraggable'
import InvoiceProcessing from '../../components/invoiceProcessing.vue'
// import QRCode from 'qrcodejs2'
const jrQrcode = require('jr-qrcode')
const Index = {
  name: 'deliveryNoteReport',
  components: {
    PageHeader,
    Adsearch,
    Print,
    vuedraggable,
    InvoiceProcessing
  },
  watch: {
    printSaleOrderVisible(a) {
      // if (a) {
      //   return this.info = JSON.parse(JSON.stringify(this.$store.state.index.printTemplateBase))
      // }
      // return this.info = {}
    }
  },
  mounted() {
    // 出库发货跳转来打印
    console.log('this.$route.params', this.$route.params)
    if (this.$route.params.isStockParams) {
      this.print_order(this.$route.params.isStockParams)
    }
  },
  mixins: [tableMixin, root],
  data() {
    return {
      paginationName1: 'tablePagination1',
      tableBackground1: true,
      tableTotalSize1: 0,
      tablePageSize1: 10,
      pagerCount1: 5,
      selects: [],
      visible: false,
      root_name: 'ddjhdbb',
      root_msg: '交货单报表',
      searchWords: {
        PageIndex: 1,
        PageSize: 10,
        KeyWords: ''
      },
      // 弹窗搜索
      formSearch: {
        PageIndex: 1,
        PageSize: 10,
        KeyWords: '',
        DeliveryID: ''
      },
      tableData1: [],
      printSaleOrderVisible: false,
      pritInfo: {
        SellCompanyName: '',
        CompanyName: '',
        AgentCompanyName: '',
        DeliveryTime: '',
        SendAddress: '',
        DeliveryID: '',
        SapDeliveryID: '',
        tableList: []
      },
      row: {},
      data_info: { DeliveryReportCustomInfo: '' },
      info: {},
      visibleType: false,
      visibleTypeC: 0,
      maxPrientNum: 300
    }
  },
  methods: {
    // 选择打印类型
    selectType(type) {
      this.visibleType = false
      this.visibleTypeC = type
      if (type === 1) return this.DeliveryTipsPrintView()
      this.DeliveryPrintView()
    },
    // 唯一码打印
    async DeliveryPrintView() {
      if (this.selects.length !== 1) return this.$message.warning('请选择你要打印的出库标签')
      // this.formSearch.DeliveryID = this.selects[0].DeliveryID
      const result = await this.$api.GetOrderDeliveryDetailsInfoList({
        DeliveryID: this.selects[0].DeliveryID,
        QueryType: 2,
        PageIndex: 1,
        pageSize: -1
      })
      if (result.RetCode !== '0') return this.$message.error(result.RetMsg)
      if (!Array.isArray(result.Message)) return this.$message.info('返回的数据不是数组')
      console.log(result)
      const newDataLast = [] // 最终的数据
      let item_temp = {} // 这里是为了处理二维码的信息
      result.Message.forEach((item, index) => {
        if (item.Num > this.maxPrientNum) return alert(`数量大于${this.maxPrientNum}将不会打印`)
        for (let i = 0; i < item.Num; i++) {
          item_temp = JSON.parse(JSON.stringify(item))
          item_temp.i = i + 1
          // UV1|交货单编号|物料编号|批号|效期|交货单行号|流水号
          item_temp.DeliverQRCode = `UV1|${item.SapDeliveryID || item.DeliveryID}|${item.CustomInvCode || item.InvCode}|${item.LotNum}|${item.ExpiredDateShow2}|${item.SapPOSNR || item.IDX}|${item_temp.i}`
          // this.$set(item, 'DeliverQRCode_temp', index)
          newDataLast.push(item_temp)
        }
      })
      console.log(newDataLast)
      if (!newDataLast.length) return this.$message.info('没有任何数据可打印')
      var printWindow = window.open(Math.random() * 1000, '_blank', '')
      printWindow.document.open()
      printWindow.document.write('<html><head><meta http-equiv="Pragma" content="no-cache" /><meta http-equiv="Cache-Control" content="no-cache" /><meta http-equiv="Expires" content="0" /><title>' + '打印' + '</title>')
      printWindow.document.write(`<style type="text/css">
            .main {
              /* width设置越宽，最终打印到纸上的字体越小 */
              // width: 300px;
              // width:50mm;
              // height:30mm;
              max-width:400px;
              height: auto;
              position: relative;
              box-sizing: border-box;
              font-size: 14px;
              page-break-after: always;
              margin: 5px;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items:flex-start;
              text-align: justify;
              word-break: break-all;
          }
          .left_view {
            display: flex;
            justify-content: space-between;
          }
          .flex {
            display:flex;
          }
          .j-end{
            justify-content: flex-end;
          }
          .flex-col {
            flex-direction: column;
          }
          .flex_1 {
            flex:1;
          }
    html,
    body {
        font-size: 6px;
        margin: 0;
        padding: 0;
    }
      </style>`
      )
      printWindow.document.write('</head><body>')
      //  这里开始写入
      let dom = ''
      for (let i = 0; i < newDataLast.length; i++) {
        const item = newDataLast[i]
        dom += `
          <div class='main' id='main'>
            <div class="flex" style='justify-content: space-between;width:100%'>
              <div class='flex_1'>
                <div>${item.SapDeliveryID || item.DeliveryID}+${this.setSapPOSNR(item.SapPOSNR) || item.IDX}+${item.i}</div>
                <div>出库日期: ${this.$minCommon.formatDate1(item.DeliveryTime, 'yyyy-MM-dd')}</div>
                <div>物料编码: ${item.InvCode}</div>
                <div>品牌: ${item.BrandName}</div>
                <div>原厂编码: ${item.ManuPartNum}</div>
                <div>规格: ${item.Specification}</div>
                <div>批号:${item.LotNum}</div>
                <div>效期: ${item.ExpiredDateShow}</div>
              </div>
              <img width='120' height='120' style='padding-left:10px;object-fit: contain;' src='${this.createrQCode(item.DeliverQRCode)}' />
            </div>
            <div style='display: flex; flex-direction: column;width:100%'>
              名称: ${item.MaterialName} 
              <div class="flex j-end" >${item.i}/${item.Num}</div>
            </div>
          </div>
        
        `
      }
      printWindow.document.write(dom)
      // 这里结束写入
      printWindow.document.write('</body></html>')
      printWindow.document.close()
      printWindow.focus()
      setTimeout(function() {
        printWindow.print()
        printWindow.close()
      }, 100)
    },
    // 处理数据
    setSapPOSNR(str) {
      if (!str) return str
      if (str.length > 2) {
        if (str[0] + str[1] === '00') {
          return str.substr(2)
        }
        return
      }
      return str
    },
    // 关联发票成功回调
    successEvent() {
      this.selects = []
      this.getTableDataAsync(1)
    },
    // 判断名称是否相同
    isSetArr(arr) {
      const obj = []
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].AgentCompanyName) {
          if (obj.indexOf(arr[i].AgentCompanyName) === -1) {
            // 没有这个值
            obj.push(arr[i].AgentCompanyName)
          }
        }
        if (arr[i].CompanyName) {
          if (obj.indexOf(arr[i].CompanyName) === -1) {
            // 没有这个值
            obj.push(arr[i].CompanyName)
          }
        }
      }
      return obj
    },
    // 发票关联
    fpAbout() {
      if (!this.selects.length) return this.$message.info('请选择要关联发票的交货单')
      // AgentCompanyName: null
      // CompanyName: "测试医院20"
      //
      const arr = this.isSetArr(this.selects)
      if (arr.length > 1) return this.$message.info('请选择相同终端名称的交货单关联发票')
      let str = ''
      this.selects.forEach((item, index) => {
        if (index !== this.selects.length - 1) {
          return str += item.IDX + ','
        }
        return str += item.IDX
      })
      this.$api.GetInvoiceList({
        idxs: str
      }).then(res => {
        if (res.RetCode !== '0') return this.$message.info(res.RetMsg)
        this.$refs.InvoiceProcessing.setData(res.Message)
      })
      return this.$refs.InvoiceProcessing.open()
    },
    // 列拖拽
    // columnDrop() {
    //   const wrapperTr = document.querySelector('.el-table__header-wrapper tr')
    //   console.log('wrapperTr', wrapperTr)
    //   this.sortable = Sortable.create(wrapperTr, {
    //     animation: 180,
    //     delay: 0,
    //     onEnd: ({ newIndex, oldIndex }) => {
    //       console.log('newIndex', newIndex, oldIndex)
    //     }
    //   })
    // },
    // 拖拽排序
    // rowDrop() {
    //   const tbody = document.querySelector('.el-table__body-wrapper tbody')
    //   Sortable.create(tbody, {
    //     onEnd: ({ newIndex, oldIndex }) => {
    //       console.log(newIndex, oldIndex)
    //       // const currRow = this.tableData.splice(oldIndex, 1)[0]
    //       // this.tableData.splice(newIndex, 0, currRow)
    //     }
    //   })
    // },
    print_open() {
      // this.GetOrderDeliveryPrintInfo()
      this.$refs.printa.open(this.row)
      console.log(this.row)
      // this.printSaleOrderVisible = false
    },
    // 获取模板数据
    GetOrderDeliveryPrintInfo() {
      this.$api.GetOrderDeliveryPrintInfo().then(res => {
        console.log(res)
      })
    },
    dealExportData() {
      this.searchWords.SelectIDList = []
      this.selects.forEach(item => {
        this.searchWords.SelectIDList.push(item.DeliveryID)
      })
      this.$api.ExprotGetOrderDeliveryInfoList(this.searchWords).then(res => {
        delete this.searchWords.SelectIDList
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.$minCommon.downloadFile(res.Message)
      })
    },
    printCord() {
      if (this.selects.length <= 0) return this.$message.warning('请选择需要打印的交货单')
      let idx = ''
      this.selects.forEach((item, index) => {
        idx += item.IDX + '|'
      })
      idx = idx.slice(0, idx.length - 1)
      this.$router.push({ name: 'PrintDeliveryNote', params: { idx: idx }})
    },
    select(row) {
      this.selects = row
    },
    selectAll(e) {
      this.selects = e
    },
    // 打印标签
    DeliveryTipsPrint() {
      let deliveryIDList = ''
      if (this.selects.length <= 0) return this.$message.warning('请选择你要打印的出库标签')
      this.selects.forEach(item => {
        deliveryIDList += item.IDX + '|'
      })
      deliveryIDList = deliveryIDList.slice(0, deliveryIDList.length - 1)
      this.$api.DeliveryTipsPrint({
        deliveryIDList: deliveryIDList
      }).then(res => {
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.$message.success('缓存成功，请在客户端获取待打印文档')
      })
    },
    // 打印出库标签，测试打印
    async  DeliveryTipsPrintView() {
      // http://test.saas.api.mai47.com/api/GetOrderDeliveryDetailsInfoList
      // var aa = document.createElement('div')

      if (this.selects.length !== 1) return this.$message.warning('请选择你要打印的出库标签')
      // this.formSearch.DeliveryID = this.selects[0].DeliveryID
      const result = await this.$api.GetOrderDeliveryDetailsInfoList({
        DeliveryID: this.selects[0].DeliveryID,
        QueryType: 2,
        PageIndex: 1,
        pageSize: -1
      })
      if (result.RetCode !== '0') return this.$message.error(result.RetMsg)
      if (!Array.isArray(result.Message)) return this.$message.info('返回的数据不是数组')
      const newDataLast = [] // 最终的数据
      let item_temp = {} // 这里是为了处理二维码的信息
      result.Message.map((item, index) => {
        console.log('item.Num', item.Num)
        if (item.Num > this.maxPrientNum) return alert(`数量大于${this.maxPrientNum}将不会打印`)
        for (let i = 0; i < item.Num; i++) {
          item_temp = JSON.parse(JSON.stringify(item))
          item_temp.i = i + 1
          item_temp.DeliverQRCode = `${item.PrintQRInvcode}|${item.LotNum}|${item.ExpiredDateShow2}|(0${i + 1})|${item.WMSLotNo}`
          // this.$set(item, 'DeliverQRCode_temp', index)
          newDataLast.push(item_temp)
        }
      })
      console.log('最终的数据', newDataLast)
      if (!newDataLast.length) return this.$message.info('没有任何数据可打印')
      // 开始修改
      // const newArr = []
      // for (let i = 0; i < newDataLast.length; i++) {
      //   newArr.push(newDataLast[i]['SendCompanyName'])
      //   newArr.push('品牌：' + newDataLast[i]['BrandName'])
      //   newArr.push('物料编码：' + newDataLast[i]['PrintQRInvcode']) //  InvCode
      //   newArr.push('原厂编号：' + newDataLast[i]['ManuPartNum'])
      //   newArr.push('效期：' + newDataLast[i]['ExpiredDateShow'])
      //   newArr.push('批号：' + newDataLast[i]['LotNum'])
      //   // newArr.push('名称：' + newDataLast[i]['MaterialName'])
      //   // newArr.push('规格：' + newDataLast[i]['Specification'])
      // }
      // const newBrr = []
      // for (let i = 0; i < newDataLast.length; i++) {
      //   newBrr.push('名称：' + newDataLast[i]['MaterialName'])
      //   newBrr.push('规格：' + newDataLast[i]['Specification'])
      // }
      // 开始修改
      var printWindow = window.open(Math.random() * 1000, '_blank', '')
      printWindow.document.open()
      printWindow.document.write('<html><head><meta http-equiv="Pragma" content="no-cache" /><meta http-equiv="Cache-Control" content="no-cache" /><meta http-equiv="Expires" content="0" /><title>' + '打印' + '</title>')
      //   printWindow.document.write(`<style type="text/css">
      //         .main {
      //           /* width设置越宽，最终打印到纸上的字体越小 */
      //           // width: 300px;
      //           height: auto;
      //           // min-width: 50mm;
      //           // height:30mm;
      //           position: relative;
      //           // display: flex;
      //           // flex-direction: column;
      //           // justify-content: space-between;
      //           box-sizing: border-box;
      //           font-size: 6px;
      //           // padding-left: 1mm;// 20px;
      //           // padding-right:1mm;// 10px;
      //           // padding-bottom:1mm;// 10px;
      //           // padding-top: 1mm;// 15px;
      //           page-break-after: always;
      //           // background:red;
      //           margin: 5px;
      //           // border:1px solid #ccc;
      //           // margin-top:5mm;

      //       }
      //       .left_view {
      //         display: flex;
      //         justify-content: space-between;
      //         // flex-direction: column;
      //         // flex:1
      //       }

      //       .name {
      //           // width: 180px;
      //           // max-height: 16px;
      //           font-size: 6px;
      //       }
      //       .name_1 {
      //         width:50mm;
      //       }
      //       .flex {
      //         display:flex;
      //       }
      //       .flex-col {
      //         flex-direction: column;
      //       }
      //       .flex_1 {
      //         flex:1;
      //       }
      //       // .el-1 {
      //       //   // width: 170px;
      //       //   overflow: hidden;
      //       //   text-overflow: ellipsis;
      //       //   display: -webkit-box;
      //       //   -webkit-line-clamp: 1;
      //       //   -webkit-box-orient: vertical;
      //       //   display: -webkit-box;
      //       //   box-sizing: border-box;
      //       //   word-break: break-all;
      //       // }
      //       .el-2 {
      //         // word-break: break-all;
      //       }
      //       .code {
      //           width: 100px;
      //           height: 100px;
      //           // position: absolute;
      //           // border: 1px solid #ccc;
      //           // right: 15px;
      //           // top: 20px;
      //           // z-index: 9999;
      //       }

      //       .nums {
      //           // position: absolute;
      //           // right: 15px;
      //           // bottom: 10px;
      //           // z-index: 9999;
      //       }
      //       @page {
      //         // size: 60mm 40mm landscape; // 横向
      //         // width: 60mm;
      //         // height:40mm;
      //         // margin-top: 20px;
      //         // margin:1mm;
      //         // padding:1mm;
      //         // font-size: 1mm;
      //      }

      // html,
      // body {
      //     font-size: 6px;
      //     margin: 0;
      //     padding: 0;
      // }
      //   </style>`
      //   )
      printWindow.document.write(`<style type="text/css">
    .main {
      /* width设置越宽，最终打印到纸上的字体越小 */
      // width: 300px;
      // width:50mm;
      // height:30mm;
      max-width:800;
      height: auto;
      position: relative;
      box-sizing: border-box;
      font-size: 14px;
      page-break-after: always;
      margin: 5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items:flex-start;
      text-align: justify;
      word-break: break-all;
  }
  .left_view {
    display: flex;
    justify-content: space-between;
  }
  .flex {
    display:flex;
  }
  .j-end{
    justify-content: flex-end;
  }
  .flex-col {
    flex-direction: column;
  }
  .flex_1 {
    flex:1;
  }
html,
body {
font-size: 6px;
margin: 0;
padding: 0;
}
</style>`
      )
      printWindow.document.write('</head><body>')
      this.setDom1(printWindow, newDataLast)
      // this.setDom(printWindow, newArr, newDataLast, newBrr)
      printWindow.document.write('</body></html>')
      printWindow.document.close()
      printWindow.focus()
      setTimeout(function() {
        printWindow.print()
        printWindow.close()
      }, 100)
      // deliveryIDList += item.IDX + '|'
    },
    createrQCode(value) {
      var base64_img = jrQrcode.getQrBase64(value, {
        // padding: 2, // 二维码四边空白（默认为10px）
        // width: 50, // 二维码图片宽度（默认为256px）
        // height: 50, // 二维码图片高度（默认为256px）
        // correctLevel: 2, // 二维码容错level（默认为高）
        reverse: false, // 反色二维码，二维码颜色为上层容器的背景颜色
        background: '#ffffff', // 二维码背景颜色（默认白色）
        foreground: '#000000' // 二维码颜色（默认黑色）
      })
      return base64_img
    },
    setDom1(printWindow, newDataLast) {
      //  这里开始写入
      let dom = ''
      for (let i = 0; i < newDataLast.length; i++) {
        const item = newDataLast[i]
        dom += `
          <div class='main' id='main'>
            <div class="flex" style='justify-content: space-between;width:100%'>
              <div class='flex_1'>
                <div>${item.SendCompanyName}</div>
                <div>品牌：${item.BrandName}</div>
                <div>物料编码：${item.InvCode}</div>
                <div>原厂编号: ${item.ManuPartNum}</div>
                <div>效期: ${item.ExpiredDateShow}</div>
                <div>批号:${item.LotNum}</div>
                <div>规格: ${item.Specification}</div>
              </div>
              <img width='120' height='120' style='padding-left:10px;object-fit: contain;' src='${this.createrQCode(item.DeliverQRCode)}' />
            </div>
            <div style='display: flex; flex-direction: column;width:100%'>
              名称: ${item.MaterialName} 
              <div class="flex j-end" >${item.i}/${item.Num}</div>
            </div>
          </div>
        
        `
      }
      printWindow.document.write(dom)
    },
    setDom(printWindow, data, newDataLast, newBrr) {
      const arr = data
      const brr_new = newBrr
      let content = ''
      let bottom_view = ''
      const brr = []
      const crr = []
      console.log('arr', arr)
      for (let i = 0; i < brr_new.length; i++) {
        if (i !== brr_new.length - 1) {
          bottom_view += `<div class='name el-2'>${brr_new[i]}</div>`
        }
        // bottom_view += `<div class='name el-2'>${brr_new[i]}</div>`
        if ((i + 1) % 2 === 0) {
          crr.push(bottom_view)
          bottom_view = ''
        }
      }
      for (let i = 0; i < arr.length; i++) {
        content += `<div class='name el-2'>${arr[i]}</div>`
        if ((i + 1) % 6 === 0) {
          brr.push(content)
          content = ''
        }
      }
      console.log('newDataLast', newDataLast)
      var dom1 = ''
      let j = 1
      for (let i = 0; i < brr.length; i++) {
        if (j > newDataLast[i].Num) {
          j = 1
        }
        const nums = `<div class='nums'>${j}/${newDataLast[i].Num}</div>`
        const src = this.createrQCode(newDataLast[i].DeliverQRCode + '')
        dom1 += `<div class='main' id='main'>
                  <div class="flex">
                    <div class="flex_1">${brr[i]}</div>
                    <div class="code">
                      <img width='100' height='100'  src='${src}' />
                    </div>
                  </div>

                  ${crr[i]}
                  <div class="flex j-between" >
                    <div style="flex: 1"> ${brr_new[brr_new.length - 1]}</div>
                     ${nums}
                  </div>
                 </div>`

        // dom1 += `<div class='main' id='main'><div class="left_view">${brr[i]}</div>  ${code}${nums}</>`
        j += 1
      }
      printWindow.document.write(dom1)
    },
    viewDetail(row) {
      this.visible = true
      this.formSearch.DeliveryID = row.DeliveryID
      this.row = row
      this.getDetail(1)
    },
    setTemplate() {

    },
    // 初始化模板數據
    initTemaplateData() {
      this.info = {}
      this.info = { // 自定义打印模板基础数据  非新增或删除  不要做改动
        titleArr: [
          { label: '客户名称', prop: 'SendCompanyName', isChecked: true },
          { label: '供应商名称', prop: 'SellCompanyName', isChecked: false },
          { label: '销售出货单', prop: 'desc', isChecked: true }
        ],
        header: [
          { label: '送达方', prop: 'SendCompanyName', isChecked: true, value: '', width: 42, sort: null },
          { label: 'WMS单号', prop: 'WMSOrderNo', isChecked: true, value: '', width: 35, sort: null },
          { label: '发票号码', prop: 'InvoiceNo', isChecked: true, value: '', width: 20, sort: null },
          { label: '售达方', prop: 'AgentCompanyName', isChecked: true, value: '', width: 42, sort: null },
          { label: '发货单号', prop: 'DeliveryID', isChecked: true, value: '', width: 35, sort: null },
          { label: '出库单号', prop: 'StockOutId', isChecked: true, value: '', width: 35, sort: null },
          { label: '出库日期', prop: 'DeliveryTimeFormat', isChecked: true, value: '', width: 20, sort: null },
          { label: '送货地址', prop: 'SendAddress', isChecked: true, value: '', width: 42, sort: null },
          { label: '币别', prop: 'CurrencyType', isChecked: true, value: '', width: 55, sort: null },
          // 以上为默认展示
          { label: '订单号', prop: 'OrderID', isChecked: false, value: '', width: 35, sort: null },
          { label: '采购单编号', prop: 'SimsOrderID', isChecked: false, value: '', width: 35, sort: null },
          { label: '开票日期', prop: 'InvoiceDateFormat', isChecked: false, value: '', width: 35, sort: null },
          { label: '卖方SAP交货单号', prop: 'SapDeliveryID', isChecked: false, value: '', width: 20, sort: null },
          { label: '快递名称', prop: 'ExpressName', isChecked: false, value: '', width: 42, sort: null },
          { label: '快递单号', prop: 'ExpressNo', isChecked: false, value: '', width: 20, sort: null },
          { label: '供应商', prop: 'SellCompanyName', isChecked: false, value: '', width: 35, sort: null },
          { label: '供货单位地址', prop: 'SellCompanyAddress', isChecked: false, value: '', width: 42, sort: null },
          { label: '供货单位开户银行', prop: 'SellBankName', isChecked: false, value: '', width: 20, sort: null },
          { label: '供货单位账号', prop: 'SellBankAccountNo', isChecked: false, value: '', width: 35, sort: null },
          { label: '物流单号', prop: 'LogisticsCode', isChecked: false, value: '', width: 35, sort: null },
          { label: '订单备注', prop: 'OrderRemark', isChecked: false, value: '', width: 20, sort: null },
          { label: '交货单创建时间', prop: 'WriteTimeFormat', isChecked: false, value: '', width: 42, sort: null },
          { label: '订单创建时间', prop: 'OrderWriteTimeFormat', isChecked: false, value: '', width: 20, sort: null },
          { label: '拣货时间', prop: 'PickedTimeFormat', isChecked: false, value: '', width: 20, sort: null }
        ],
        header_desc: { label: '我是第一个描述的数据', isChecked: false },
        table: [
          { label: '物料编号', isChecked: true, prop: 'InvCode' },
          { label: '原厂编号', isChecked: true, prop: 'ManuPartNum' },
          { label: '物料名称', isChecked: true, prop: 'MaterialName' },
          { label: '品牌', isChecked: true, prop: 'BrandName' },
          { label: '规格\/型号', isChecked: true, prop: 'Specification' },
          { label: '单位', isChecked: true, prop: 'StockUnitN' },
          { label: '物料映射编号', isChecked: true, prop: 'CustomInvCode' },
          { label: '标类', isChecked: true, prop: 'StandardType' },
          { label: '批号', isChecked: true, prop: 'LotNum' },
          { label: '生产日期', isChecked: true, prop: 'ProductDateFormat' },
          { label: '有效截止', isChecked: true, prop: 'ExpiredDateFormat' },
          { label: '数量', isChecked: true, prop: 'DeliveryNumFormat' },
          { label: '含税单价', isChecked: true, prop: 'SapKBETR2Format' },
          { label: '含税金额', isChecked: true, prop: 'SapKWERTFormat' },
          { label: '注册证号', isChecked: true, prop: 'RegisterNo' },
          { label: '生产企业许可证', isChecked: true, prop: 'ProductionLicence' },
          { label: '生产企业', isChecked: true, prop: 'ProductFactory' },
          { label: '储存', isChecked: true, prop: 'StorageConditionName' },
          // 以上为默认展示的字段
          { label: '物料类型', isChecked: false, prop: 'MaterialTypeName' },
          { label: '注册证失效期', isChecked: false, prop: 'RegisterEndFormat' },
          { label: '序号', isChecked: false, prop: 'ROWNUM' },
          { label: '发票号码', isChecked: false, prop: 'InvoiceNo' },
          { label: '订购数量', isChecked: false, prop: 'OrderNumFormat' },
          { label: '物料子类型', isChecked: false, prop: 'MaterialSubTypeName' },
          { label: '批次', isChecked: false, prop: 'LotNo' },
          { label: '交货单号', isChecked: false, prop: 'DeliveryID' },
          { label: '使用科室', isChecked: false, prop: 'SimsSectionName' },
          { label: '订单明细备注', isChecked: false, prop: 'ODIRemark' },
          { label: '交货单明细时间', isChecked: false, prop: 'WriteTimeFormat' }

        ],
        foot_table: [
          { label: '备注', isChecked: true, prop: 'OrderRemark' },
          { label: '总金额', isChecked: true, prop: 'SumSapKWERTFormat' }
        ],
        footer_desc: { label: '我是第二个描述的数据', isChecked: false },
        footer: [
          { label: '发票号', prop: '', isChecked: true, value: '', width: 20 },
          { label: '制单', prop: '', isChecked: true, value: '', width: 20 },
          { label: '货物签收', prop: '', isChecked: true, value: '', width: 20 },
          { label: '发票签收', prop: '', isChecked: true, value: '', width: 20 },
          { label: '货票签收', prop: '', isChecked: true, value: '', width: 20 },
          { label: '到货温度', prop: '', isChecked: true, value: '', width: 20 },
          { label: '签收日期', prop: '', isChecked: true, value: '', width: 20 },
          { label: '签收日期', prop: '', isChecked: true, value: '', width: 20 },
          { label: '签收日期', prop: '', isChecked: true, value: '', width: 20 }

        ]
      }
    },
    setPrintData(newDate) {
      console.log('处理数据', newDate)
      // 处理头部信息
      for (let i = 0; i < this.info.titleArr.length; i++) {
        for (let j = 0; j < newDate.titleArr.length; j++) {
          if (this.info.titleArr[i].prop === newDate.titleArr[j].prop) {
            Object.keys(this.info.titleArr[i]).map(item => {
              this.info.titleArr[i][item] = newDate.titleArr[j][item]
            })
          // this.$set(this.info.titleArr[i], i, newDate.titleArr[j])
          }
        }
      }
      // 处理表头
      for (let i = 0; i < this.info.header.length; i++) {
        for (let j = 0; j < newDate.header.length; j++) {
          if (this.info.header[i].prop === newDate.header[j].prop) {
            Object.keys(this.info.header[i]).map(item => {
              this.info.header[i][item] = newDate.header[j][item]
            })
          }
        }
      }
      this.info.header.sort((a, b) => a.sort - b.sort) // 重新排序
      // 处理第一个自定义描述 header_desc
      this.info.header_desc = newDate.header_desc
      // 处理表格 table
      for (let i = 0; i < this.info.table.length; i++) {
        for (let j = 0; j < newDate.table.length; j++) {
          if (this.info.table[i].prop === newDate.table[j].prop) {
            Object.keys(this.info.table[i]).map(item => {
              this.info.table[i][item] = newDate.table[j][item]
            })
            // this.$set(this.info.table[i], i, newDate.table[j])
          }
        }
      }
      // 处理第一个自定义描述 footer_desc
      this.info.footer_desc = newDate.footer_desc
      // 处理底部表头 footer
      this.info.footer = newDate.footer
      console.log('info', this.info)
      // this.info = obj
      this.printSaleOrderVisible = !this.printSaleOrderVisible
    },
    print_order(row) {
      this.printmaxNumTable = 5
      this.row = row
      this.initTemaplateData()
      const form = {
        DeliveryID: row.DeliveryID,
        CustomerID: row.SendCompanyID,
        CompanyID: row.SellCompanyID
      }
      this.$api.GetOrderDeliveryPrintInfo(form).then(res => {
        console.log(res)
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.Re)
        this.data_info = res.Message // OrderDeliveryDetailslist
        this.pritInfo.tableList = Array.isArray(res.Message.OrderDeliveryDetailslist) ? res.Message.OrderDeliveryDetailslist : []

        if (this.data_info.DeliveryReportCustomInfo !== null) {
          const newArrjson = JSON.parse(this.data_info.DeliveryReportCustomInfo.PrintJson)
          // 先排序 头部
          newArrjson.header.map((item, index) => {
            this.$set(item, 'sort', index)
          })
          // console.log('头部排序后的数据', newArrjson)
          // 如果info, 则处理数据
          return this.setPrintData(newArrjson)
        }
        this.printSaleOrderVisible = !this.printSaleOrderVisible
      })
    },
    async handleCurrentChange1(page) {
      console.log(page)
      this.tableLoading = true
      //   this.catchAsync(
      // { Message, Total }
      const { Message, Total, RetCode } = await this.getDetail(page, this.tablePageSize1)
      this.tableLoading = false
      if (RetCode === '0' && Message.length > 0) {
        this.tableTotalSize1 = Total
        this.tableData1 = Message
      }
    },
    // 获取交货单物料详情
    async getDetail(page, pageSize) {
      this.formSearch.PageIndex = page || 1
      this.formSearch.PageSize = this.tablePageSize1
      const response = await this.$api.GetOrderDeliveryDetailsInfoList(this.formSearch)
      if (response.RetCode !== '0') return this.$message.error(response.RetMsg)
      this.tableData1 = response.Message
      this.tableTotalSize1 = response.Total
      return response
    },
    // 复制到页面上实现获取数据逻辑
    async getTableDataAsync(page, pageSize) {
      this.searchWords.PageIndex = page || 1
      this.searchWords.PageSize = this.tablePageSize
      const response = await this.$api.GetOrderDeliveryInfoList(this.searchWords)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      console.log(response)
      return response
    },
    //  打印
    print() {
      var title = ''
      // var divElements = ''
      const top_view_print = document.getElementById('top_view_print').innerHTML
      const bottom_view_print = document.getElementById('bottom_view_print').innerHTML
      const table_footer = document.getElementById('table_footer').innerHTML
      // console.log(divElements)
      var printWindow = window.open(Math.random() * 1000, '_blank', '')
      // // 构造一个表格
      let str_tr = ''
      let str_td = ''
      let num = 0
      this.info.table.map(item => {
        if (item.isChecked) {
          num += 1
          str_tr += `
            <th style="font-size: 12px;font-weight:400;min-width:100px" >${item.label}</th>
        `
        }
      })
      this.pritInfo.tableList.map((item, index) => {
        this.info.table.map((item1, index1) => {
          if (item1.isChecked) {
            str_td += `<td  id="td_c" style="text-align:center;border:1px solid #666;font-size: 12px">${item[item1.prop] ? item[item1.prop] : ''}</td>]`
          }
        })
      })
      const td_c = str_td.split(']')
      td_c.splice(str_td.split(']').length - 1, 1)
      let arr = [] // 共有多少表格行数及内容
      arr = this.group(td_c, num)
      const table_item = [] // 每一个分页的表格内容
      const crr = this.groupSet(this.printmaxNumTable, arr) // 按照一页最大打印表格数量分组
      for (let i = 0; i < crr.length; i++) {
        let a = ''
        crr[i].map((item, index) => {
          a += `<tr>${item.join('')}</tr>`
        })
        table_item.push(a)
      }
      printWindow.document.open()
      printWindow.document.write('<html><head><meta http-equiv="Pragma" content="no-cache" /><meta http-equiv="Cache-Control" content="no-cache" /><meta http-equiv="Expires" content="0" /><title>' + title + '</title>')
      printWindow.document.write(`<style type="text/css">
       html, body {
         font-size: 12px;
         margin: 0;
       }
      .p-lr-20 {padding: 0 20px; }
      .m-bottom-20 {margin-bottom: 20px; }
      .weight { font-weight: 700 }
      .f14 { font-size:12px}
      .f20 {font-size:12px}
      .flex {display:flex}
      .j-center {justify-content: center;}
      .column { flex-direction: column;}
      .a-center {align-items: center;}
      .f-warp { flex-wrap: wrap; }
      .j-start {justify-content: flex-start;}
      .m-tb-10 {margin: 10px 0;}
      .m-tb-20 {margin: 20px 0;}
      .m-right-20 {nargin-right: 20px}
      .j-between {justify-content: space-between;}
      .m-top-10 {margin-top: 10px }
      .element.style {width: auto; }
      .table_info {
        max-width: 1000px;
        margin: 0 auto;
      }
      .table_footer {
        height:25px;
        border:1px solid #666;
        border-top:none;
        line-height:25px;
     }
     .table_footer_1 {
        border:1px solid #666;
     }
     .table_footer_2 {
        height:25px;
        line-height:25px;
        border-left:1px solid #666;
        border-right:1px solid #666;
        border-bottom:1px solid #666;
     }
      table{table-layout: fixed;}
      td{word-break: break-all; word-wrap:break-word;}
      .div-0 {
        height: auto;
        page-break-after: always;
      }
      </style>`
      )
      printWindow.document.write('</head><body>')
      let table_ = ''
      const dom_item = [] // 多少页
      table_item.map(item => {
        // 组装每页表格
        table_ = ''
        table_ = `
              <table border="1" class='table-0' id="table_main_v" cellpadding="0" cellspacing="0" style="border-collapse:collapse;border:1px solid #666;width:100%">
              <tr>
                ${str_tr}
              </tr>
              ${item}
            </table>
          `
        // 组装每页的内容
        dom_item.push(`<div class='div-0'>${top_view_print + table_ + table_footer + bottom_view_print}</div>`)
      })
      dom_item.map(item => {
        printWindow.document.write(item)
      })
      printWindow.document.write('</body></html>')
      printWindow.document.close()
      printWindow.focus()
      setTimeout(function() {
        printWindow.print()
        printWindow.close()
      }, 100)
    }
  }
}
export default Index
