<template>
  <div class="deliveryNoteReport" style="min-height: calc(100vh - 190px);">
    <PageHeader title="交货单报表">
      <!-- <el-button type="primary" @click="print_open">打印模板测试</el-button> -->
    </PageHeader>
    <div class="searchView flex a-center m-tb-10 j-between">
      <div class="flex m-left-20" style="flex: 1">
        <el-input
          v-model="searchWords.KeyWords"
          placeholder="可通过订单号、销售单号、交货单号、供应商名称、买方名称搜索"
          class="rule-input-edit SearchBox_30"
        >
          <template slot="prepend">
            <i class="el-icon-s-operation cursor" />
            <span class="cursor textcolor333"> 高级搜索</span>
          </template>
          <div
            slot="append"
            class="cursor select_none"
            @click="searchGetTableDataAsync()"
          >
            搜 索
          </div>
        </el-input>
      </div>
      <div class="flex m-left-10 m-right-20">
        <el-button type="success" size="mini" plain round @click="printCord">交货单二维码</el-button>
        <el-popover
          v-model="visibleType"
          class="OrderDropDown1"
          placement="bottom"
          trigger="manual"
          :width="150"
          popper-class="test_pop_view"
          style="margin:0 5px"
        >
          <div class="flex column">
            <el-button type="success" plain size="mini" round @click="selectType(2)">唯一码</el-button>
            <div class="p-top-5" />
            <el-button type="primary" plain size="mini" round @click="selectType(1)">出库标签New</el-button>
            <!-- <div class="p-bottom-10 cursor" @click="selectType(1)">出库标签New</div> -->
            <!-- <div class="cursor" @click="selectType(2)">唯一码</div> -->
          </div>
          <div slot="reference" class="out white bg1 cursor p-tb-5 min-border-right flex a-center f14 pointer" @click="visibleType = !visibleType">
            <div>{{ visibleTypeC == 1 ? '出库标签New' : (visibleTypeC == 2 ? '唯一码' : '标签打印') }}</div>
            <i class="el-icon-caret-bottom" />
          </div>
        </el-popover>
        <!-- <el-button type="primary" plain size="mini" round @click="DeliveryTipsPrintView">出库标签New</el-button> -->
        <el-button type="primary" plain size="mini" round @click="DeliveryTipsPrint">出库标签</el-button>
        <el-button type="warning" size="mini" plain round @click="fpAbout">关联发票</el-button>
      </div>
      <div class="flex m-left-10 m-right-20">
        <div
          class="ModelBttton-white-30"
          @click="dealExportData"
        >
          <i class="el-icon-upload2 f15 p-right-5" /> 导出
        </div>
      </div>
    </div>
    <div class="p-lr-20">
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        :header-cell-style="headerCStyle"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        height="calc(100vh - 325px)"
        row-key="IDX"
        border
        @select-all="selectAll"
        @select="select"
      >
        <af-table-column type="selection" width="38" fixed="left" />
        <el-table-column prop="DeliveryID" label="交货单号" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.SapDeliveryID ? scope.row.SapDeliveryID : scope.row.DeliveryID }}
          </template>
        </el-table-column>
        <el-table-column prop="OrderID" label="订单单号" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-link type="primary" :underline="false" @click="$router.push({ name: 'orderDetails1', params: {orderid: scope.row.OrderID, ishandler: '-1' }} )">{{ scope.row.OrderID }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="CompanyName" label="终端" align="center" show-overflow-tooltip />
        <el-table-column prop="AgentCompanyName" label="买方" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.AgentCompanyName ? scope.row.AgentCompanyName : scope.row.CompanyName }}
          </template>
        </el-table-column>
        <el-table-column prop="LogisticsCode" label="物流单号" align="center" show-overflow-tooltip />
        <el-table-column prop="DeliveryTime" label="发货时间" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $minCommon.formatDate(new Date(scope.row.DeliveryTime) , 'yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>
        <el-table-column prop="WriteTime" label="创建时间" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $minCommon.formatDate(new Date(scope.row.WriteTime), 'yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>
        <el-table-column prop="StatusStr" label="状态" align="center" show-overflow-tooltip />
        <el-table-column prop="set" align="center" label="操作" fixed="right" width="50">
          <!--  slot-scope="scope" -->
          <template slot-scope="scope">
            <el-popover
              placement="left"
              trigger="hover"
              popper-class="test_pop_view"
            >
              <div class="DropDownList">
                <div v-if="$minCommon.checkMeau('ddjhdbbview', userBottons)" class="OperationButton">
                  <el-link link :underline="false" @click="viewDetail(scope.row)"><i class="el-icon-edit p-right-5" /> 查看详情</el-link>
                </div>
                <div v-if="$minCommon.checkMeau('ddjhdbbprint', userBottons)" class="OperationButton">
                  <el-link :underline="false" @click="print_order(scope.row)"><i class="el-icon-printer p-right-5" /> 打印交货单</el-link>
                </div>
              </div>
              <i slot="reference" class="el-icon-more" />
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container flex j-between a-center">
        <!-- <el-button type="primary" @click="fpAbout">发票关联</el-button> -->
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page.sync="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <!-- 交货单详情 start -->
    <el-dialog class="DepartmentBox" append-to-body :visible.sync="visible" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">交货单详情</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => visible = false" />
      </div>
      <div class="searchView flex a-center j-between SearchBox_30 p-top-20">
        <div class="flex" style="flex: 1">
          <el-input
            v-model="formSearch.KeyWords"
            size="medium  "
            placeholder="可通过物料名称、物料编号搜索"
            class="rule-input-edit"
          >
            <template slot="prepend">
              <i class="el-icon-search" />
            </template>
            <div
              slot="append"
              class="cursor select_none"
              @click="getDetail(1)"
            >
              搜 索
            </div>
          </el-input>
          <div class="out p-left-10 flex a-center">
            <el-button
              class="ModelBttton-white-30"
              round
              type="primary"
              icon="el-icon-printer"
              @click="print_order(row)"
            >打印交货单</el-button>
          </div>
        </div>
      </div>
      <div style="height:20px" />
      <el-table
        ref="testTable"
        v-loading="tableLoading"
        :data="tableData1"
        tooltip-effect="dark"
        show-header
        style="width: 100%"
        :header-cell-style="headerCStyle"
        border
      >
        <el-table-column
          type="selection"
          width="40"
          max-height="500px"
          fixed="left"
        />
        <el-table-column align="center" property="InvCode" label="物料编码" show-overflow-tooltip />
        <el-table-column align="center" property="MaterialName" label="物料名称" show-overflow-tooltip width="150" />
        <el-table-column align="center" property="BrandName" label="品牌" show-overflow-tooltip width="150" />
        <el-table-column align="center" property="MaterialTypeName" label="物料类型" show-overflow-tooltip width="120" />
        <el-table-column align="center" property="Specification" label="规格/型号" show-overflow-tooltip width="150" />
        <el-table-column align="center" property="StockUnitN" label="单位" show-overflow-tooltip width="60" />
        <el-table-column align="center" property="LotNum" label="批号" show-overflow-tooltip width="100" />
        <el-table-column prop="ExpiredDate" label="效期" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $minCommon.setDateFormate(scope.row.ExpiredDate) }}
          </template>
        </el-table-column>
        <el-table-column align="center" property="Num" label="数量" show-overflow-tooltip />
      </el-table>

      <div class="pagination-container p-bottom-20">
        <el-pagination
          :ref="paginationName1"
          :total="tableTotalSize1"
          :pager-count="pagerCount1"
          :page-size="tablePageSize1"
          :background="tableBackground1"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange1($event)"
        />
      </div>
    </el-dialog>
    <!-- 交货单详情 end -->
    <el-dialog title="打印交货单" custom-class="dialog_view_print" center :visible="printSaleOrderVisible" :show-close="false" :close-on-click-modal="true">
      <!-- 打印表单 this.$store.state.index.printTemplateBase-->
      <div v-if="printSaleOrderVisible" class="content_view_print">
        <div id="printDom" class="table_info p-lr-20 " style="height:auto">
          <div id="top_view_print">
            <!-- 标题 start -->
            <div class="header weight f20 flex j-center column a-center m-bottom-20">
              <template v-for="(item,index) in info.titleArr">
                <span v-if="item.isChecked" :key="index">
                  {{ item.prop !== 'desc' ? data_info.DeliveryInfo[item.prop] : item.label }}
                </span>
              </template>
            </div>
            <!-- 标题 end -->
            <!-- 表头 start -->
            <div class="flex f-warp t_b_view m-tb-10">
              <template v-for="(item,index) in info.header">
                <div v-if="item.isChecked" :key="index" :ref="'dom_' +index" class="mid_info item_ flex j-between a-center select_none" :style="{width: item.width +'%'}">
                  <div class="content flex ">
                    <div>{{ item.label }}：</div>
                    <div>{{ data_info.DeliveryInfo[item.prop] }}</div>
                  </div>
                </div>
              </template>
            </div>
            <!-- 表头 end -->
            <!-- 描述 start-->
            <div v-if="info.header_desc.isChecked" class="header weight f14 flex j-start a-center m-tb-10">
              {{ info.header_desc.label }}
            </div>
          <!-- 描述 end-->
          </div>
          <!-- 表格 start :span-method="arraySpanMethod"-->
          <div class="m-top-10" style="width: 100%">
            <el-table
              id="pritInfotablelist"
              ref="multipleTable"
              tooltip-effect="dark"
              show-header
              style="width:100%"
              row-key="id"
              :data="pritInfo.tableList"
              border
            >
              <template v-for="(item,index) in info.table">
                <af-table-column v-if="item.isChecked" :key="index" align="center" :prop="item.prop" :label="item.label" />
              </template>
            </el-table>
          </div>
          <!-- 表格 end-->
          <!-- 合计 备注 start -->
          <div id="table_footer">
            <div v-if="info.foot_table[0].isChecked" class=" flex p-lr-20 header table_footer">
              <div style="margin-right:20px">{{ info.foot_table[0].label }} </div>
              {{ data_info.DeliveryInfo.OrderRemark }}
            </div>
            <div v-if="info.foot_table[1].isChecked" class="flex p-lr-20 header table_footer table_footer_2" :class="info.foot_table[0].isChecked ? '': 'table_footer_1' ">
              <div style="margin-right:20px">{{ info.foot_table[1].label }} </div>
              {{ data_info.DeliveryInfo.SumSapKWERTFormat }}
            </div>
          <!-- 合计 备注 end -->
          </div>

          <div id="bottom_view_print">
            <!-- 描述 start-->
            <div v-if="info.footer_desc.isChecked" class="header weight f14 flex j-start a-center m-tb-10">
              {{ info.footer_desc.label }}
            </div>
            <!-- 描述 end-->
            <!-- 表头 start -->
            <div class="flex f-warp t_b_view m-tb-10">
              <template v-for="(item,index) in info.footer">
                <div v-if="item.isChecked" :key="index" :ref="'dom_' +index" class="mid_info item_ flex j-between a-center select_none m-tb-10" :style="{width: item.width +'%'}">
                  <div class="content flex">
                    <div>{{ item.label }}</div>
                    <div>{{ item.value }}</div>
                  </div>
                </div>
              </template>
            </div>
          <!-- 表头 end -->
          </div>
        </div>
      </div>
      <div slot="footer" class="caozuo t-right p-top-20 t_line_s m-top-10 flex j-between a-center">
        <div class="flex j-start a-center f12" style="width:50%">
          设置一页最多打印条数：
          <el-input v-model.number="printmaxNumTable" style="width:50px" size="mini" @change="tableMaxNum" />
          <!-- <el-input-number v-model="printmaxNumTable" controls-position="right" size="mini" :min="1" :max="pritInfo.tableList.length" :controls="true" @change="tableMaxNum" /> -->
        </div>
        <div>
          <el-button @click="print_open">设置打印模板</el-button>
          <el-button @click="printSaleOrderVisible = false">取 消</el-button>
          <el-button type="primary" @click="print">打印</el-button>
        </div>

      </div>
    </el-dialog>
    <Print ref="printa" :companyid="row.SellCompanyID" :custominfo="info" :customerid="row.SendCompanyID" />
    <!-- 发票管理 start -->
    <InvoiceProcessing ref="InvoiceProcessing" @success="successEvent" />
    <!-- 发票管理 end -->
  </div>
</template>
<script>
import Index from '@/minxin/orderMananger/deliveryNoteReport'
export default Index
</script>
<style>
.el-dialog__header{
  padding: 0;
  display: none;
}
.el-dialog--center .el-dialog__body{
  padding: 0;
}
</style>
<style>
/* .dialog_view_print {
  display: flex;
  /*  margin:0 160px 48px */
/* .dialog_view_print {
  width: auto;
} */
@media screen and (min-width: 1000px) and (max-width: 1100px) {
  .dialog_view_print {
     width: 90% !important;
  }

}
@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .dialog_view_print {
     width: 80% !important;
  }
}
@media only screen and  (min-width: 1501px) {
  .dialog_view_print {
     width: 60% !important;
  }
}
@media only screen and  (min-width: 500px) and (max-width: 1000px) {
  .dialog_view_print {
     width: 90% !important;
  }
}
@media only screen and  (min-width: 850px) and (max-width: 950px) {
  .dialog_view_print {
     width: 90% !important;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .dialog_view_print {
     width: 80% !important;
  }
}
.table_info {
  box-sizing: border-box;
}
.table_info {
  max-width: 1000px;
  margin: 0 auto;
}
</style>
<style scoped lang='scss'>
@import "@/style/search.scss";
.table_footer {
   height:25px;
   border:1px solid #EBEEF5;
   border-top:none;
   line-height:25px;
}
.table_footer_1 {
   border:1px solid #EBEEF5;
}
.table_footer_2 {
   height:25px;
   line-height:25px;
   border-left:1px solid #EBEEF5;
   border-right:1px solid #EBEEF5;
   border-bottom:1px solid #EBEEF5;
}
.a{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:flex-start;
  object-fit: contain;
  justify-content: space-between;
}
</style>
